<template lang="pug">
    .list-w
      .top
        .top-l
          MTitleI(title="资讯列表")
        .top-r.cur-btn(@click="onAdd" v-if="userInfo.readyOnly == 1") 新增
      GeminiScrollbar(class="my-scroll-w" style="height:92%;")  
        MyListItem(:item="item" v-for='item in advisoryList' :readonly="userInfo.readyOnly == 1" @onChangeItem="onChangeItem")
        div(style="height:10px;")
      MyModal1(:modalShow="dialogVisible" marginTop="10vh" :title="modalTitle" :modal="true" @onClose="onClose")
        .form-box
          el-form(ref="form" :hide-required-asterisk="true" :rules="rules" :model="form" label-width="7.5rem")
            el-form-item(label="标题：" class="my-form-1" prop="title")
              Box3
                el-input(v-model="form.title" maxlength="30" placeholder="请输入标题，30个字以内")
            el-form-item(label="内容简介：" class="my-form-1" prop="introduction")
              Box3
                el-input(v-model="form.introduction" placeholder="请输入内容简介")
            el-form-item(label="上传列表图：" class="my-form-2" prop="infoImage") 
              el-upload(
              :action="uploadUrl"
              ref="uploader"
              class="my-uploader"
              :limit="1"
              name="files"
              :multiple="false"
              list-type="picture-card"
              :auto-upload="true"
              :file-list="fileList"
              :on-success="onSuccess"
              :on-error="onError"
              :on-change="onChangeFile"
              :on-exceed="onExceed"
              :on-remove="onRemoveFile"
            )
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip upload-place">图片尺寸：240px * 140px</div>   
            el-form-item(label="内容：" class="my-form-3" prop="content")
              Tinymce(ref="editor" :contents="form.content")
        .bottom-btn-box
          .btn-item.btn-item1(@click="onBack") 返回
          .btn-item.cur-btn(@click="onRelease") 发布         
</template>

<script>
import MyModal1 from "../../components/MyModal1.vue";
import MyListItem from "../../components/MyListItem.vue";
import Box3 from "../../components/Box3.vue";
import Tinymce from "../../components/Tinymce.vue";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false, //modal 显示隐藏
      modalIdx: 0, //用来判断 modal title
      fileList: [], //文件list
      form: {
        //表单key 双向数据绑定
        title: "",
        content: "",
        introduction: "",
        infoImage: "",
      },
      rules: {
        //form 验证规则
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
        introduction: [
          { required: true, message: "请输入内容简介", trigger: "blur" },
        ],
        infoImage: [{ required: true, message: "请添加图片", trigger: "blur" }],
      },
    };
  },
  components: {
    MyModal1,
    MyListItem,
    Box3,
    Tinymce,
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.app.userInfo,
      pagation: (state) => state.advisoryCenter.pagation, //分页信息
      advisoryList: (state) => state.advisoryCenter.advisoryList, //列表数据
    }),
    modalTitle() {
      //modal title
      return this.modalIdx === 1 ? "编辑资讯" : "新增资讯";
    },
    uploadUrl() {
      //附件上传地址
      return process.env.NODE_ENV === "production"
        ? window.g.UPLOADER_URL
        : "/api/common/uploadFile";
    },
  },
  created() {
    this.getAdvisoryListAction();
  },
  methods: {
    ...mapActions([
      "getAdvisoryDetailAction",
      "getAdvisoryListAction",
      "deleteAdvisoryAction",
      "saveorupdateinfoAction",
    ]),
    //文件移除
    onRemoveFile(file, fileList) {
      if (fileList.length === 0) {
        this.form.infoImage = "";
      }
    },
    //文件长传个数超出回调
    onExceed() {
      this.$message({
        type: "error",
        message: "最多上传只能上传一张图片，请删除已上传图片，再进行操作！",
      });
    },
    //清除form
    clearForm() {
      this.form = {
        title: "",
        content: "",
        introduction: "",
        infoImage: "",
      };
      this.$refs["form"].resetFields();
      this.fileList = [];
      this.$refs.editor.setContent();
      this.$nextTick(() => {
        window.tinymce.get("tinymce").setContent("");
      });
    },
    //list delete edit 操作事件
    onChangeItem(idx, item) {
      // idx:0 编辑 1:删除 2:标题
      let id = item.informationId;
      if (idx === 2) {
        this.getAdvisoryDetailAction({ id });
        this.$router.push({ path: "/advisory_center/detail" });
      } else if (idx === 0) {
        this.dialogVisible = true;
        let json = {
          title: item.title,
          content: item.content,
          introduction: item.introduction,
          infoImage: item.infoImage,
        };
        this.form = json;
        this.curItemId = id;
        this.modalIdx = 1;
        if (item.infoImage) {
          this.fileList.push({ url: item.infoImage, name: "附件：" });
        }
        this.$nextTick(() => {
          this.$refs.editor.setContent(item.content);
        });
      } else {
        this.openConfim(async () => {
          let res = await this.deleteAdvisoryAction({ id });
          if (res.result) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.getAdvisoryListAction();
        });
      }
    },
    openConfim(sucCallback) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          if (sucCallback && typeof sucCallback === "function") {
            sucCallback();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //文件change
    onChangeFile(file, fileList) {
      const { size } = file;
      const isLt10M = size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message({
          message: "上传文件大小不能超过 10MB!",
          type: "warning",
        });
      }
      this.$refs.uploader.abort();
    },
    //文件上传成功
    onSuccess(response, file, fileList) {
      this.form.infoImage = response.resultObject;
    },
    //文件上传失败
    onError(err, file, fileList) {
    },
    //返回事件
    onBack() {
      this.clearForm();
      this.dialogVisible = false;
    },
    //发布事件
    onRelease() {
      let editContent = this.$refs.editor.content;
      this.form.content = editContent;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = {
            informationId: this.curItemId,
            informationType: 1,
            ...this.form,
          };
          let res = await this.saveorupdateinfoAction(params);
          if (res.result) {
            this.$message({
              message: "保存成功！",
              type: "success",
            });
            this.clearForm();
            this.dialogVisible = false;
            this.getAdvisoryListAction();
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        }
      });
    },
    //鼠标单击的事件
    onClick(e, editor) {
    },
    //modal 关闭
    onClose() {
      this.clearForm();
      this.dialogVisible = false;
    },
    //新增事件
    onAdd() {
      this.dialogVisible = true;
      this.curItemId = "";
      this.modalIdx = 0;
      this.clearForm();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .my-modal-body {
  padding-top: 30px;
}
.my-uploader {
  .df;
}
.form-box {
  /deep/ .my-form-2 {
    .el-form-item__content {
      height: 6.4rem;
    }
  }
  /deep/ .el-form-item__label {
    font-size: 14px;
    color: #5a86d3;
  }
  /deep/ .el-upload-list__item {
    width: 100px;
    height: 100px;
  }
  .upload-place {
    color: #5a86d3;
    font-size: 14px;
    padding-left: 10px;
    margin-top: 70px;
  }
  .my-form-3 {
    /deep/ .mce-edit-area,
    /deep/ .mce-panel,
    /deep/ .mce-tinymce {
      background: transparent;
      border: 1px solid #2b58a6;
    }
    /deep/ .mce-menubar,
    /deep/ .mce-btn {
      background: transparent;
      border: none;
    }
    /deep/ .mce-top-part {
      * {
        color: #5b9ad4;
      }
    }
    /deep/ .mce-statusbar {
      display: none;
    }
    /deep/ .mce-toolbar-grp {
      border: none;
    }
    /deep/ .mce-toolbar {
      border-top: 1px solid #2b58a6;
    }
  }
}
.list-w {
  width: 68%;
  margin: 0 auto;
  background: rgba(28, 42, 117, 0.4);
  min-height: 200px;
  padding: 20px;
  color: #fff;
  overflow: hidden;
  height: 84vh;
  .top {
    .df;
    .dfjs;
    .dfac;
    padding-bottom: 16px;
  }
}
.bottom-btn-box {
  .df;
  .jcc;
  .btn-item {
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    margin: 10px 20px;
    cursor: pointer;
  }
  .btn-item1 {
    color: #318ef0;
    border: 1px solid #318ef0;
    border-radius: 2px;
  }
  .btn-item1:hover {
    color: #318ef0;
    border: 0.0625rem solid #318ef0;
    background: rgba(65, 105, 225, 0.3);
  }
}
/deep/ .my-scroll-w {
  .gm-scroll-view::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .list-item {
    width: calc(100% - 25px);
  }
}

// 滚动条样式
</style>
